.pillar-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns with equal width */
  height: 90vh; /* Span the whole vertical space */
  width: 100%; /* Span the whole horizontal space */
}

.pillar-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px;
  display: flex; /* Use flexbox for vertical alignment */
  flex-direction: column; /* Stack children vertically */
  justify-content: center; /* Center children vertically */
}

.pillar-item h2 {
  font-size: 45px; /* Adjust this value as needed */
  text-align: center;
  margin-bottom: 15px;
}

.pillar-item p {
  font-size: 32px; /* Adjust this value as needed */
  position: relative; /* Needed to position the pseudo-element */
  padding-left: 30px; /* Make space for the bullet point */
}

.pillar-item p::before {
  content: ''; /* Add a pseudo-element for the bullet point */
  position: absolute; /* Position it absolutely within the paragraph */
  left: 0; /* Align to the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for exact centering */
  width: 20px; /* Width of the bullet point */
  height: 20px; /* Height of the bullet point */
  background-image: url('../image/blooddrop.png'); /* Add your blood drop logo */
  background-size: contain; /* Make sure the logo fits the element */
  background-repeat: no-repeat; /* Do not repeat the background */
}

/* Remove gaps between grid items */
.pillar-container > .pillar-item:not(:last-child) {
  border-right: none;
}

/* Responsive adjustments */
@media screen and (max-width: 960px) {
  .pillar-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .pillar-container {
    grid-template-columns: 1fr;
  }
}
