.about-container {
    text-align: center;
    margin: 20px;
}

.board-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    padding: 20px;
}

.board-member {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
    justify-content: space-between; /* This will push the child elements apart */
    height: 100%; /* Give it a height to space content */
}

.board-image-container {
    width: 150px;
    height: 150px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f7f7;
    border: 3px solid #eee;
    margin-bottom: 20px;
}

.board-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.board-info {
    text-align: center;
}

.board-info strong {
    color: red;
    display: block; /* Ensure it takes up full width for centering */
    font-size: 1.2em; /* Adjustable font size for position */
}

.board-info p {
    font-size: 1.6em; 
}


.board-info strong,
.board-info p {
    margin: 0; 
    padding: 5px 0; 
}

.board-member {
    overflow: hidden; 
}


