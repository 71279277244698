.events-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: insert 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.events-container > e1 {
    color: black;
    font-size: 40px;
    margin-top: -100px;
}
