.body-container img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }

.body-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: insert 0 0 0 1000px rgba(0,0,0,0.2);
    object-fit: contain;
}

.body-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
}

.body-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
}

.body-btns {
    margin-top: 32px;
}

.body-btns .btn {
    margin: 6px;
}

@media screen and (max-width: 960px) {
    .body-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    
  }
  
  @media screen and (max-width: 768px) {
    .body-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .body-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }